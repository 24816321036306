.favourites-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.favourites-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.favourites-kitchen-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.favourites-kitchen-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.favourites-kitchen-item:hover {
  transform: translateY(-2px);
}

.favourites-image-container {
  position: relative;
  width: 40%; /* Adjust as needed for image width */
  min-width: 200px; /* Ensure a minimum width for small screens */
  height: 200px;
  overflow: hidden;
}

.favourites-image-slider {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}

.favourites-kitchen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-shrink: 0;
}

.favourites-img-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 10;
}

.favourites-arrow-left {
  left: 0.5rem;
}

.favourites-arrow-right {
  right: 0.5rem;
}

.favourites-kitchen-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%; /* Remaining space for details and button */
  padding-left: 1.5rem;
}

.favourites-kitchen-info {
  flex-grow: 1;
}

.favourites-kitchen-info h3 {
  margin: 0 0 0.5rem;
  color: #e63946;
  font-size: 1.5rem;
}

.favourites-kitchen-info p {
  margin: 0.3rem 0;
  color: #666;
  font-size: 1rem;
}

.favourites-unfavourite-btn {
  background-color: #e63946;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.favourites-unfavourite-btn:hover {
  background-color: #d62828;
}

.favourites-message {
  text-align: center;
  color: #555;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.favourites-message.favourites-error {
  color: #e63946;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .favourites-image-container {
    width: 35%;
    min-width: 150px;
  }

  .favourites-kitchen-details {
    width: 60%;
  }

  .favourites-kitchen-info h3 {
    font-size: 1.3rem;
  }

  .favourites-kitchen-info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .favourites-container {
    padding: 1rem;
  }

  .favourites-container h1 {
    font-size: 1.5rem;
  }

  .favourites-kitchen-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .favourites-image-container {
    width: 100%;
    height: 150px;
    margin-bottom: 1rem;
  }

  .favourites-kitchen-details {
    width: 100%;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .favourites-kitchen-info h3 {
    font-size: 1.25rem;
  }

  .favourites-kitchen-info p {
    font-size: 0.9rem;
  }

  .favourites-unfavourite-btn {
    margin-top: 1rem;
    align-self: flex-end;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .favourites-container {
    padding: 0.5rem;
  }

  .favourites-container h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .favourites-image-container {
    height: 120px;
  }

  .favourites-kitchen-info h3 {
    font-size: 1.1rem;
  }

  .favourites-kitchen-info p {
    font-size: 0.85rem;
  }

  .favourites-message {
    font-size: 1rem;
  }
}