.user-menu-tabs {
  background-color: #f9f9f9f9;
  color: #333;
  padding: 16px;
  margin-top: 10px;
  font-family: "Nunito Sans", sans-serif;
}

.user-menu-tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.user-menu-tabs li {
  margin-bottom: 10px;
}

.user-menu-tabs .menu-link {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.user-menu-tabs .menu-link:hover {
  background-color: #f2f2f2;
  transform: none;
  border-radius: 5px;
}

.user-menu-tabs .menu-link.active {
  background-color: #e0e0e0;
  transform: none;
  border-radius: 5px;
}

.user-menu-tabs .logout-button {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: medium;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.user-menu-tabs .logout-button:hover {
  background-color: #f2f2f2;
  transform: none;
}

.user-menu-tabs .logout-button:focus {
  outline: none;
}

.user-menu-tabs .icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

/* Accessibility: Focus States */
.user-menu-tabs .menu-link:focus,
.user-menu-tabs .logout-button:focus {
  outline-offset: 1px;
}

.user-menu-tabs .menu-toggle {
  background: none;
  border: none;
  color: black;
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 15px;
  box-shadow: 0px 0px 5px #c8c8c8;
}

.user-menu-tabs .arrow-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.user-menu-tabs .arrow-icon.open {
  transform: rotate(180deg);
}

/* Dropdown menu for mobile */
.user-menu-tabs .dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.user-menu-tabs .dropdown-menu li {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}

.user-menu-tabs .dropdown-menu .menu-link {
  width: 97%;
  justify-content: flex-start;
  padding-left: 20px;
  background: transparent;
}

.user-menu-tabs .dropdown-menu .menu-link:hover {
  background-color: rgba(47, 87, 255, 0.2);
  transform: none;
  box-shadow: none;
}

/* Show full menu on desktop, hide dropdown */
@media (min-width: 769px) {
  .user-menu-tabs .full-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* Animation for mobile dropdown */
@media (max-width: 768px) {
  .user-menu-tabs {
    padding: 5px;
    border-radius: 5px;
  }

  .user-menu-tabs .dropdown-menu {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }

  .user-menu-tabs .dropdown-menu.show {
    max-height: 1000px;
    opacity: 1;
  }

  /* Dropdown link hover effect for mobile */
  .user-menu-tabs .dropdown-menu .menu-link:hover {
    background-color: #7e7e7e;
    transform: translateX(5px);
    color: #ffffff;
  }

  /* Ensure smooth toggle on mobile */
  .user-menu-tabs .menu-toggle:focus {
    outline: none;
    box-shadow: 0px 0px 10px #7e7e7e;
  }
}

@media (max-width: 1024px) {
  .user-menu-tabs {
    padding: 5px;
    border-radius: 5px;
  }

  .user-menu-tabs .dropdown-menu {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }

  .user-menu-tabs .dropdown-menu.show {
    max-height: 1000px;
    opacity: 1;
  }

  /* Dropdown link hover effect for mobile */
  .user-menu-tabs .dropdown-menu .menu-link:hover {
    background-color: #7e7e7e;
    transform: translateX(5px);
    color: #ffffff;
  }

  /* Ensure smooth toggle on mobile */
  .user-menu-tabs .menu-toggle:focus {
    outline: none;
    box-shadow: 0px 0px 10px #7e7e7e;
  }
}
