.main-kitchen-page {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
}

.kitchen-page {
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Nunito Sans", sans-serif;
}

.kitchen-title h1 {
  color: black;
  font-size: 32px;
  margin: 0;
  margin-top: 5px;
  font-weight: bold;
}

.desktop-title {
  display: block;
}

.mobile-title {
  display: none;
}
/* Image Gallery Styling */
.kitchen-image-gallery {
  width: 100%;
  margin-bottom: 10px;
  filter: drop-shadow(1px 2px 3px rgb(151, 151, 151));
}

/* Desktop View */
.desktop-gallery {
  display: flex;
  gap: 10px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.desktop-gallery .main-image {
  flex: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
}

.main-image:hover {
  opacity: 0.8;
  cursor: pointer;
}

.desktop-gallery .side-images {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  height: 100%;
  align-items: stretch;
}

.desktop-gallery .side-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.desktop-gallery .side-image-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}

.desktop-gallery .side-image-wrapper:nth-child(2) {
  border-radius: 0 10px 0 0;
}

.desktop-gallery .side-image-wrapper:nth-child(4) {
  border-radius: 0 0 10px 0;
}

.kitchen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.desktop-gallery .main-image .kitchen-image {
  position: absolute;
  top: 0;
  left: 0;
}

.desktop-gallery .side-image-wrapper .kitchen-image {
  position: absolute;
  top: 0;
  left: 0;
}

.kitchen-image.faded {
  opacity: 0.5;
}

/* Mobile View */
.mobile-gallery {
  display: none;
  position: relative;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.mobile-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.mobile-image-slider {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.mobile-image-wrapper {
  flex: 0 0 100%;
  height: 100%;
}

.mobile-image-wrapper .kitchen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 5;
}

.img-arrow:hover {
  opacity: 1;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

.img-arrow:focus {
  outline: none;
}

/* Full Page Image Preview Styling */
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.image-preview-overlay.active {
  opacity: 1;
  visibility: visible;
}

.image-preview-container {
  position: relative;
  width: 90%;
  height: 90%;
  max-width: 1200px;
  max-height: 80vh;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.preview-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.preview-arrow:hover {
  background-color: rgba(255, 255, 255, 1);
}

.preview-arrow-left {
  left: 20px;
}

.preview-arrow-right {
  right: 20px;
}

.image-preview-close {
  position: absolute;
  top: -50px;
  right: -50px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.image-preview-close:hover {
  background-color: rgba(255, 255, 255, 1);
}

/* Content Layout */
.kitchen-content {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.kitchen-details-container {
  flex: 2;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.kitchen-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.kitchen-details-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.kitchen-details-row p {
  margin: 0;
  font-size: 16px;
}

.kitchen-header p {
  font-size: 16px;
  margin: 0%;
  color: var(--secondary-color);
}

/* Dropdown Sections */
.dropdown-section {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.dropdown-section:hover {
  background-color: #e9ecef;
}

.dropdown-header {
  color: var(--primary-color);
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.dropdown-header::after {
  content: "▼";
  position: absolute;
  right: 0;
  transition: transform 0.3s ease;
}

.dropdown-section.active .dropdown-header::after {
  transform: rotate(180deg);
}

.dropdown-content {
  color: var(--text-color);
  padding-top: 10px;
  display: none;
}

.dropdown-section.active .dropdown-content {
  display: block;
  animation: slideDown 0.3s ease-out;
}

.dropdown-section .dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-content h4 {
  margin-top: 15px;
  margin-bottom: 5px;
  color: var(--secondary-color);
}

.dropdown-content ul {
  list-style-type: disc;
  margin-left: 20px;
  color: var(--text-color);
}

.dropdown-content ul li {
  margin-bottom: 5px;
}

/* Rates and Availability Table */
.rates-responsive {
  overflow-x: auto;
}

.rates-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.rates-table th,
.rates-table td {
  border: 1px solid var(--border-color);
  padding: 10px;
  text-align: left;
}

.rates-table th {
  color: white;
}

.rates-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Booking Section */
.kitchen-booking-section {
  flex: 1;
  padding: 25px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 20px;
}

.kitchen-booking-section h3 {
  color: var(--primary-color);
  font-size: 24px;
  margin-bottom: 20px;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.booking-form label {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: bold;
}

.booking-form input,
.booking-form select {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-color);
  transition: border-color 0.3s ease;
}

.booking-form input:focus,
.booking-form select:focus {
  border-color: var(--primary-color);
  outline: none;
}

.booking-form .time-selection {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.booking-form .start-time,
.booking-form .end-time {
  flex: 1;
}

.booking-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.booking-message.success {
  background-color: #d4edda;
  color: #155724;
}

.booking-message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.booking-message.warning {
  background-color: #fff3cd;
  color: #856404;
}

.message-close-icon {
  cursor: pointer;
  align-self: flex-end;
}

.booking-message p {
  margin: 5px 0 0;
  font-style: italic;
}

.total-amount,
.total-hours,
.total-days {
  font-size: 16px;
  margin-top: 10px;
}

.cost-breakdown {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}

.cost-breakdown table {
  width: 100%;
  border-collapse: collapse;
  border: none !important;
}

.cost-breakdown td {
  padding: 10px;
  text-align: left;
}

.cost-breakdown tr:last-child td {
  border-top: 2px solid var(--border-color);
}

.cost-breakdown td:nth-child(2) {
  text-align: right;
}

.booking-btn {
  background: linear-gradient(90deg, var(--primary-color), #ff6f61);
  color: white;
  padding: 14px;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.booking-btn:hover {
  background: linear-gradient(90deg, #c92c35, #ff4b5c);
}

.booking-error-message {
  display: flex;
  align-items: center;
  background-color: #ffe6e6;
  color: #cc0000;
  padding: 8px;
  border-radius: 5px;
  margin: 0;
  position: relative;
}

.error-close-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #cc0000;
  font-size: 16px;
}

.loading-message {
  display: flex;
  align-items: center;
  background-color: #e6f7ff;
  color: #00529b;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.loading-message span {
  margin-left: 8px;
}

.booking-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.social-links span {
  font-weight: bold;
  color: var(--light-text-color);
  margin-right: 10px;
  font-size: 16px;
}

.social-links a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.social-links a:hover {
  background-color: var(--primary-color);
  color: white;
  transform: scale(1.1);
}

.social-links i {
  font-size: 18px;
}

/* Modal Styling */
.kitchen-page-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.kitchen-page-modal-content {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-out;
}

.kitchen-page-modal-content h2 {
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.kitchen-page-modal-body {
  margin-top: 15px;
  color: var(--light-text-color);
  line-height: 1.6;
}

.kitchen-page-modal-link {
  display: inline-block;
  margin-top: 20px;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.kitchen-page-modal-link:hover {
  color: #d62828;
}

.kitchen-page-modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.kitchen-page-modal-close:hover {
  color: #d62828;
}

.kitchen-page-modal-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.kitchen-page-modal-icon.success {
  color: var(--success-color);
}

.kitchen-page-modal-icon.error {
  color: var(--error-color);
}

/* Renter Terms Checkbox */
.renter-terms-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.renter-terms-container input[type="checkbox"] {
  accent-color: var(--primary-color);
  width: 18px;
  height: 18px;
  transition: transform 0.2s ease;
}

.renter-terms-container input[type="checkbox"]:hover {
  transform: scale(1.1);
}

.renter-terms-container label {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.terms-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.terms-link:hover {
  color: #d62828;
}

.verification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.verification-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.verification-modal h2 {
  margin-bottom: 10px;
  color: #333;
}

.verification-modal p {
  margin-bottom: 20px;
  color: #555;
}

.verification-modal ul {
  text-align: left;
  margin-bottom: 20px;
}

.verification-modal-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.verification-modal-btn:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .kitchen-content {
    flex-direction: column;
  }

  .kitchen-booking-section {
    position: static;
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .desktop-gallery {
    display: none;
  }

  .mobile-gallery {
    display: block;
    height: 300px;
  }
  .kitchen-page-modal-content {
    width: 90%;
    padding: 20px;
  }

  .kitchen-details-row {
    flex-direction: column;
  }

  .kitchen-title h1 {
    font-size: 22px;
  }

  .desktop-title {
    display: none;
  }

  .mobile-title {
    display: block;
  }

  .dropdown-header {
    font-size: 18px;
  }

  .kitchen-booking-section h3 {
    font-size: 20px;
  }

  .dropdown-section {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .dropdown-content {
    font-size: 14px;
    line-height: 1.6;
  }

  .rates-table {
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
    overflow-x: auto;
  }
  .rates-responsive table,
  .rates-responsive thead,
  .rates-responsive tbody,
  .rates-responsive th,
  .rates-responsive td,
  .rates-responsive tr {
    display: block;
  }
  .rates-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .rates-responsive tr {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
  }
  .rates-responsive td {
    border: none;
    position: relative;
    padding-left: 50%;
  }
  .rates-responsive td::before {
    position: absolute;
    left: 10px;
    width: 45%;
    white-space: nowrap;
    font-weight: bold;
  }
  .rates-responsive td:nth-of-type(1)::before {
    content: "Frequency";
  }
  .rates-responsive td:nth-of-type(2)::before {
    content: "Start";
  }
  .rates-responsive td:nth-of-type(3)::before {
    content: "End";
  }
  .rates-responsive td:nth-of-type(4)::before {
    content: "Price";
  }
}

@media (max-width: 480px) {
  .mobile-gallery {
    height: 200px;
  }

  .kitchen-title h1 {
    font-size: 20px;
  }

  .desktop-title {
    display: none;
  }

  .mobile-title {
    display: block;
  }

  .kitchen-details-row {
    flex-direction: column;
    gap: 10px;
  }

  .dropdown-header {
    font-size: 16px;
  }

  .kitchen-booking-section h3 {
    font-size: 18px;
  }

  .dropdown-section {
    padding: 15px;
  }

  .dropdown-content {
    font-size: 14px;
    line-height: 1.6;
  }

  .rates-table {
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
    overflow-x: auto;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
