.kitchen-documents {
  animation: fadeIn 0.3s ease-out forwards;
  margin: 0 auto;
  text-align: center;
}

.kitchen-documents h2 {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.docs-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.doc-upload-box {
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease;
  padding: 10px;
  position: relative;
}

.doc-upload-box:hover {
  background-color: #f9f9f9;
  border-color: #393939;
}

.upload-icon {
  font-size: 40px;
  margin-bottom: 8px;
}

.doc-label {
  font-size: 14px;
  margin-top: 8px;
  font-weight: bold;
}

.doc-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.doc-file-name {
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  word-break: break-word;
}

.doc-file-link {
  font-size: 14px;
  color: #1976d2;
  text-decoration: underline;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .docs-container {
    flex-direction: column;
    align-items: center;
  }

  .doc-upload-box {
    width: 100%;
    max-width: 250px;
  }
}
