.kitchen-card-link {
  text-decoration: none !important;
}

.kitchen-card-link:hover .kitchen-card {
  text-decoration: none;
  color: black;
}

.kitchen-card {
  animation: fadeIn 0.5s ease-out forwards;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
}

.kitchen-info {
  margin-top: 5px;
  margin-bottom: 7px;
}

.kitchen-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.kitchen-title {
  font-weight: bold;
  font-size: larger;
}

.kitchen-distance {
  color: #e63946;
}

.kitchen-card:hover {
  color: black;
  transform: translateY(-3px);
}

/* Favourite Button */
.favourite-btn {
  position: absolute;
  top: 7px;
  right: 7px;
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  z-index: 2;
}

.favourite-btn:hover {
  transform: scale(1.1);
}

.favourite-btn svg {
  color: rgb(200, 200, 200);
  font-size: 24px;
  filter: drop-shadow(2px 4px 6px black);
}

.favourite-btn.active svg {
  color: #e63946;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.favourite-btn.animate-pop {
  animation: pop 0.3s ease;
}

.favourite-btn.animate-shake {
  animation: shake 0.3s ease;
}

.image-container {
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 280px;
  overflow: hidden;
}

.image-slider {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.browse-kitchen-image {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.img-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 18px;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

.image-container:hover .img-arrow {
  opacity: 1;
}

.img-arrow:focus {
  outline: none;
}

@media (max-width: 480px) {
  .kitchen-card {
    max-width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
