.my-food-items-container {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.my-food-items-container h1 {
  text-align: left;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.food-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
  margin: 10px auto;
}

.my-food-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.food-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.6s ease-in-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.food-item:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.food-item h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0 0 5px;
}

.food-item p {
  margin: 4px 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.food-item-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.food-edit-btn,
.food-delete-btn {
  text-decoration: none;
  padding: 6px 10px;
  font-size: 14px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.food-item-actions .food-edit-btn {
  background-color: #393939;
}

.food-item-actions .food-edit-btn:hover {
  background-color: #555;
  transform: translateY(-2px);
}

.food-item-actions .food-delete-btn {
  background-color: #dc3545;
}
.food-item-actions .food-delete-btn:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.add-food-item-btn {
  text-decoration: none;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #393939;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.add-food-item-btn:hover {
  background-color: #000;
  transform: translateY(-2px);
  color: white;
}

.loading-food {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .my-food-items-container h1 {
    font-size: 20px;
  }

  .food-header {
    margin-bottom: 20px;
    gap: 10px;
  }

  .add-food-item-btn {
    padding: 5px;
    font-size: 12px;
    width: auto;
    align-self: flex-end;
  }

  .food-item {
    padding: 15px;
  }

  .food-item h3 {
    font-size: 16px;
  }

  .food-item p {
    font-size: 13px;
  }

  .food-item-actions .food-edit-btn,
  .food-item-actions .food-delete-btn {
    font-size: 13px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .my-food-items-container h1 {
    font-size: 18px;
  }

  .food-item h3 {
    font-size: 15px;
  }

  .food-item p {
    font-size: 12px;
  }

  .food-item-actions .food-edit-btn,
  .food-item-actions .food-delete-btn {
    font-size: 12px;
    padding: 6px;
  }
}
