.kitchen-price {
  animation: fadeIn 0.5s ease-out forwards;
  margin: 0 auto;
}

.price-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 25px;
  color: #333;
  text-align: center;
  background-color: #f0eeee;
  padding: 10px 15px;
  border-radius: 5px;
}

.price-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.price-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.kitchen-price label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.kitchen-price input,
.kitchen-price select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.kitchen-price select {
  width: 100%;
  appearance: none;
}

.info-note {
  display: block;
  text-align: center;
  margin-bottom: 15px;
  color: #666;
}

/* Availability Section */
.availability-section {
  margin-top: 20px;
  padding: 5px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  background-color: #fafafa;
}

/* Single Row Container for All Inputs and Buttons */
.availability-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.availability-row .price-form-group {
  flex: 1 1 200px;
  min-width: 180px;
}

/* Special styling for recurring switch group */
.price-form-group .recurring-group {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/* Switch CSS */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-top: 8px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Button Container */
.entry-btn-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  gap: 5px;
}

/* Availability List */
.availability-list {
  margin-top: 20px;
}

.availability-list h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.availability-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Each Availability Slot Row */
.availability-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #f7f7f7;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 8px;
}

/* Edit/Delete Actions */
.availability-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

/* Icon colors */
.slot-edit-icon {
  color: #1976d2;
  cursor: pointer;
}

.slot-delete-icon {
  color: #d32f2f;
  cursor: pointer;
}

/* Hover effects for icons */
.slot-edit-icon,
.slot-delete-icon {
  transition: transform 0.2s ease-in-out;
}

.slot-edit-icon:hover,
.slot-delete-icon:hover {
  transform: scale(1.5);
}

/* Buttons */
.add-entry-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #393939;
  color: white;
  font-weight: bold;
}

.add-entry-btn:hover {
  background-color: #000;
}

.cancel-edit-btn {
  background-color: #a00;
}

/* React DatePicker Custom Styles */
/* .react-datepicker {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  position: absolute !important;
}

body.react-datepicker-open {
  overflow: hidden;
} */

.react-datepicker-popper {
  z-index: 9999 !important;
  position: absolute !important;
}

/* Make Sure It Doesn't Overlap Other Elements */
.date-picker-wrapper {
  position: relative;
  z-index: 10;
}

/* Ensure Dropdown is Visible */
.react-datepicker {
  left: 0 !important;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
/* Responsive (Tablet & Mobile) Styles */
@media screen and (max-width: 1024px) {
  .availability-inputs {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 768px) {
  .kitchen-price {
    border-radius: 0;
    max-width: 100%;
    box-shadow: none;
  }

  .price-title {
    font-size: 18px;
    padding: 8px 12px;
  }

  .price-group {
    flex-direction: column;
    gap: 10px;
  }

  .price-form-group {
    width: 100%;
  }

  .availability-section {
    padding: 10px;
  }

  .entry-btn-container {
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .price-title {
    font-size: 16px;
    padding: 6px 10px;
  }

  .price-form-group label {
    font-size: 13px;
  }

  .kitchen-price input,
  .kitchen-price select {
    padding: 8px;
    font-size: 13px;
  }

  .add-entry-btn,
  .cancel-edit-btn {
    padding: 8px 16px;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  .availability-controls {
    flex-direction: column;
    align-items: stretch;
  }
  .entry-btn-container {
    justify-content: center;
  }
}
