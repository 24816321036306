.my-reservations-container {
  padding: 30px;
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
}

.my-reservations-container h1 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.reservations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}

.reservation-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.reservation-card:hover {
  transform: translateY(-4px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h3 {
  margin: 0;
  font-size: 1.25rem;
}

.card-header h3 a {
  color: #2c5282;
  text-decoration: none;
}

.card-header h3 a:hover {
  color: #2b6cb0;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
}

.status-confirmed {
  background: #2f855a;
}
.status-pending {
  background: #d69e2e;
}
.status-cancelled {
  background: #c53030;
}

.card-content {
  display: grid;
  gap: 20px;
}

.time-section,
.details-section,
.price-section {
  display: grid;
  gap: 8px;
}

.time-item,
.detail-item,
.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  color: #4a5568;
  font-weight: 500;
}

.value {
  color: #1a1a1a;
}

.price-item.total {
  border-top: 1px solid #e2e8f0;
  padding-top: 8px;
  font-weight: 600;
}

.loading-state,
.no-data {
  text-align: center;
  padding: 40px;
  color: #718096;
  font-size: 1.125rem;
}

@media (max-width: 768px) {
  .reservations-grid {
    grid-template-columns: 1fr;
  }
}
