* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.food-icon {
  color: #e63946;
  margin-right: 0.2rem;
  font-size: 1.2rem;
}

.orders-tag {
  display: block;
  font-weight: bold;
  text-decoration: none;
}

.orders-tag:hover {
  text-decoration: underline;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1);
  position: relative;
  z-index: 10;
}

.nav-left img.air-kitchenz-dark {
  height: 43px;
}

.nav-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex: 1;
}
.chef-playground-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px;
  color: #393939;
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}
.chef-playground-text:hover {
  color: #d62839;
  transform: scale(1.05);
}

.nav-right {
  display: flex;
  align-items: center;
}
.navbar-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
  margin-left: 1rem;
}
.menu-icon {
  font-size: 1.5rem;
}
.group {
  display: flex;
  align-items: center;
}

.profile-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile-link {
  display: flex;
  align-items: center;
  gap: 3px;
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease;
}
.profile-link:hover {
  color: #ff0000;
}
.navbar-profile-picture {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid gray;
}
.navbar-profile-picture:hover {
  border: 1px solid #ff0000;
}
.greeting {
  font-size: 1rem;
}
.logout-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Feature Links */
.feature-links {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s;
}
.feature-links:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.feature-links:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Common link hover effect */
.feature-links,
.chef-playground-text {
  position: relative;
  text-decoration: none;
  color: #393939;
}

.feature-links::after,
.chef-playground-text::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #e63946;
  transition: width 0.3s ease;
}

.feature-links:hover::after,
.chef-playground-text:hover::after {
  width: 100%;
}

/* Remove existing hover styles */
.feature-links:hover {
  transform: none;
  box-shadow: none;
}

.chef-playground-text:hover {
  transform: none;
}

/* Profile dropdown styles */
.profile-container {
  position: relative;
  padding-left: 5px;
}

.profile-dropdown {
  position: absolute;
  top: 100%;
  margin-top: 15px;
  right: 0;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.profile-container:hover .profile-dropdown {
  opacity: 3;
  visibility: visible;
  transform: translateY(0);
}
/* Hide the cart button when the profile menu is open */
.hide-cart .cart-float-button {
  display: none !important;
}

.dropdown-link {
  font-size: 0.9rem;
  display: block;
  padding: 8px 16px;
  color: #393939;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-link:hover {
  background-color: #f5f5f5;
}

.overlay-content-1 {
  display: flex;
}
/* Responsive (Mobile) */
@media (max-width: 768px) {
  .nav-toggles {
    display: flex;
    align-items: center;
  }

  /* Smaller toggle icons */
  .navbar-toggle .menu-icon {
    font-size: 1.6rem; /* Smaller size */
    color: #000;
    cursor: pointer;
  }

  /* Reduce icon container padding */
  .navbar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0%;
  }
  .nav-center {
    width: 100%;
    justify-content: center;
    margin-left: 0px;
    flex-direction: column;
    gap: 0px;
  }
  .chef-playground-text {
    font-size: 1.1rem;
  }
  .group {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 60px;
    right: 0;
    width: 220px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }
  .navbar-links.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .group div {
    padding: 10px 20px;
    text-align: center;
  }
  .group div a {
    display: block;
  }
  .nav-left img.air-kitchenz-dark {
    height: 40px;
  }
  .navbar-profile-picture {
    width: 35px;
    height: 35px;
  }
  .greeting {
    display: none;
  }
}

@media (max-width: 1024px) {
  /* Hide desktop links on mobile */
  .chef-playground-text,
  .feature-links,
  .profile-container {
    display: none;
  }

  /* Show the bag icon */
  .navbar-toggle {
    display: block;
  }

  /* Style the bag icon */
  .navbar-toggle .menu-icon {
    font-size: 1.8rem;
    color: #000;
    cursor: pointer;
  }

  /* UserMenuTabs container in toggle */
  .navbar-links {
    width: 100%;
    padding: 10px;
    background: #fff;
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 20;
  }

  .navbar-links.open {
    display: block;
  }

  .navbar-links {
    display: none;
  }

  /* Fullscreen Overlay Menu for Mobile */
  /* Fullscreen Overlay Menu for Mobile */
  .mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    z-index: 999;
    transition: height 0.4s ease, opacity 0.4s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
  }

  .mobile-overlay.open {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  .mobile-overlay .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #000;
    cursor: pointer;
  }

  .mobile-overlay .overlay-content {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile-overlay .overlay-content a {
    color: #1d1d1f;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: left;
  }

  .mobile-overlay .overlay-content a:hover {
    color: #0066cc;
  }
  .mobile-profile-picture {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
  }

  /* Border only when the user has uploaded a picture */
  .mobile-profile-picture.with-border {
    border: 1px solid black;
  }
}
