/* src/pages/Orders/Orders.css */
.orders-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .orders-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .orders-error {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .no-orders {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .order-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
  }
  
  .order-card h2 {
    margin-bottom: 8px;
    font-size: 1.2rem;
  }
  
  .order-details p {
    margin: 4px 0;
  }
  
  /* Responsive example */
  @media (max-width: 600px) {
    .orders-container {
      padding: 10px;
    }
    .order-card {
      padding: 10px;
    }
  }
  