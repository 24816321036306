.kitchen-amenities {
  animation: fadeIn 0.5s ease-out forwards;
  margin: 0 auto;
}

.kitchen-amenities h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; 
}

.kitchen-amenities h2,
.kitchen-amenities .amenity-category h3 {
  text-align: center;
}

.kitchen-amenities .amenity-category {
  margin-bottom: 30px;
}

.kitchen-amenities .amenity-category h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.kitchen-amenities .form-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.kitchen-amenities .form-group label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
}

.kitchen-amenities .form-group input[type="checkbox"] {
  accent-color: #393939;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-right: 10px;
  transition: box-shadow 0.3s ease;
}

.kitchen-amenities .form-group input[type="checkbox"]:checked {
  box-shadow: 0 0 8px #393939;
}

.loading-indicator {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.modal-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.modal-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-btn:hover {
  color: #333;
}

.my-kitchen-link {
  color: #e53935;
  text-decoration: none;
}

.my-kitchen-link:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .kitchen-amenities .form-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .kitchen-amenities {
    padding: 0%;
    border-radius: 0%;
    box-shadow: none;
  }

  .kitchen-amenities .form-group {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .kitchen-amenities .form-group label {
    font-size: 14px;
  }

  .modal-content {
    width: 80%;
    height: auto;
  }
}
