.blog-detail {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-detail h1 {
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.blog-detail img {
  display: block;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-date {
  color: #555;
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: right;
  font-style: italic;
}

.blog-content {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
  margin-top: 20px;
  padding: 0 10px;
  word-break: break-word;
}

.blog-content p {
  margin-bottom: 15px;
}

.loading-indicator {
  font-size: 1.5rem;
  color: #555;
  text-align: center;
  margin: 100px 0;
  font-weight: bold;
}

.error-message {
  font-size: 1.2rem;
  color: red;
  text-align: center;
  margin: 10px 0;
}

.blog-social-links {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.blog-social-links span {
  font-weight: bold;
  color: var(--light-text-color);
  margin-right: 10px;
  font-size: 16px;
}

.blog-social-links a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.blog-social-links a:hover {
  background-color: var(--primary-color);
  color: white;
  transform: scale(1.1);
}

.blog-social-links i {
  font-size: 18px;
}

@media (max-width: 768px) {
  .blog-detail h1 {
    font-size: 2rem;
  }

  .blog-detail img {
    max-height: 300px;
  }

  .blog-date {
    font-size: 0.9rem;
  }

  .blog-content {
    font-size: 1rem;
    padding: 0 5px;
    text-indent: 10px;
  }
}
