.kitchen-location {
  animation: fadeIn 0.5s ease-out forwards;
  margin: 0 auto;
}

.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
}

.input-with-icon input {
  padding: 12px 12px 12px 40px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.loading-indicator {
  width: 100%;
  margin-top: 10px;
  color: #555;
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .kitchen-location {
    padding: 0%;
    border-radius: 0%;
    box-shadow: none;
  }

  .kitchen-location h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .input-with-icon input {
    padding: 10px 10px 10px 36px;
    font-size: 14px;
  }

  .map-view {
    padding-top: 100%;
    height: auto;
  }
}
