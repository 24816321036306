.career-hero-section {
  position: relative;
  width: 100%;
  height: 75vh;
  background: url("../../assets/images/teamasset.png") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(1px 2px 3px rgb(67, 67, 67));
}

.career-hero-overlay {
  background: rgba(0, 0, 0, 0.6);
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
}

.career-hero-overlay h1 {
  font-size: 3rem;
  margin: 0;
  color: #f7f7f7;
  filter: drop-shadow(2px 4px 6px black);
}

.career-hero-overlay p {
  font-size: 1.5rem;
  margin: 20px 0;
  filter: drop-shadow(1px 2px 3px black);
}

.career-hero-overlay button {
  background-color: #393939;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.career-hero-overlay button:hover {
  background-color: #4a4a4a;
}

/* Categories Section */
.categories-section {
  padding: 20px;
  text-align: center;
}

.categories-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.categories-tabs {
  display: inline-flex;
  gap: 20px;
}

.categories-tabs button {
  background: none;
  border: 2px solid #393939;
  padding: 10px 20px;
  font-size: 1rem;
  color: #393939;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.categories-tabs button.active,
.categories-tabs button:hover {
  background-color: #393939;
  color: #fff;
}

.categories-dropdown {
  display: none;
  margin-top: 20px;
}

.categories-dropdown select {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #393939;
  border-radius: 4px;
}

/* Jobs Section */
.jobs-section {
  padding: 20px;
  background-color: #fff;
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.job-card {
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.job-card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.job-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
}

.toggle-icon {
  font-size: 1.5rem;
  color: #393939;
  transition: transform 0.2s ease-in-out;
}

.toggle-icon:hover {
  transform: scale(1.5);
}

.job-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #555;
  margin: 15px 0;
}

.job-details-dropdown {
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.job-detail-section {
  margin-bottom: 15px;
}

.job-detail-section h3 {
  color: #2c3e50;
  margin-bottom: 8px;
  font-size: 1.1rem;
}

.job-detail-section p,
.job-detail-section ul {
  color: #555;
  line-height: 1.6;
  font-size: 0.95rem;
}

.job-detail-section ul {
  list-style: disc inside;
  padding-left: 20px;
}

.apply-link {
  display: inline-block;
  background-color: #393939;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.apply-link:hover {
  background-color: #4a4a4a !important;
}

.no-jobs p {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Application Form */
.application-form {
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 8px;
  max-width: 600px;
  margin: 40px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.application-form h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.application-form input,
.application-form textarea,
.application-form select {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
}

.application-form button {
  background-color: #393939;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.application-form button:hover {
  background-color: #4a4a4a !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .career-hero-section {
    height: 60vh;
  }
  .career-hero-overlay h1 {
    font-size: 1.4rem;
  }
  .career-hero-overlay p {
    font-size: 1rem;
  }
  .categories-tabs {
    display: none;
  }
  .categories-dropdown {
    display: block;
  }
}
