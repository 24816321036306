/* --- RestaurantPage.css --- */

/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Page Container */
.restaurant-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Delivery Modal Backdrop */
.delivery-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

/* Delivery Modal */
.delivery-modal {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Ensure the autocomplete dropdown appears above the modal */
.pac-container {
  z-index: 10002 !important;
}

.delivery-modal .form-group input[type="text"]:focus {
  border-color: #e63946;
}

/* Error message styling */
.delivery-modal .error-message {
  font-size: 0.9rem;
}

/* The backdrop covers the entire screen */
.size-select-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* The modal is a centered box */
.size-select-modal {
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  position: relative; /* To position the buttons absolutely */
  animation: fadeInScale 0.3s ease-out;
}

.size-select-modal select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 1rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.size-select-modal select:focus {
  outline: none;
}

/* Buttons Positioned at Bottom Corners */
/* Button Styles */

.size-select-buttons {
  display: flex;
  justify-content: space-between; /* Puts buttons in corners */
  bottom: 1rem;
  left: 0;
}

.size-select-buttons button {
  flex: 1;
  max-width: 130px;
  margin-top: 10px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
}

/* Confirm Button */
.size-select-buttons button:first-child {
  background-color: #e63946;
  color: #fff;
}

.size-select-buttons button:first-child:hover {
  background-color: #d62839;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(230, 57, 70, 0.4);
}

/* Cancel Button */
.size-select-buttons button:last-child {
  background-color: #ccc;
  color: #000;
}

.size-select-buttons button:last-child:hover {
  background-color: #b3b3b3;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
/* Form Groups within Delivery Modal */
.delivery-modal .form-group {
  margin-bottom: 1rem;
}
.delivery-modal .form-group label {
  display: block;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.form-group input,
.delivery-modal .datepicker-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Delivery Modal Buttons */
.delivery-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}
.delivery-modal-buttons button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.delivery-modal-buttons button:first-child {
  background-color: #e63946;
  color: #fff;
}
.delivery-modal-buttons button:last-child {
  background-color: #ccc;
  color: #333;
}

/* Header Section */
.restaurant-header {
  width: 100%;
  padding: 0px 10px 10px 10px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.header-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}
.header-image img {
  width: 400px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}
.header-details {
  flex: 1;
  min-width: 280px;
}
.header-details h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.header-details .location {
  color: #555;
}
.header-buttons {
  margin-top: 1rem;
}
.view-menu-button {
  background-color: #e63946;
  color: #fff;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}
.view-menu-button:hover {
  background-color: #d62839;
}

/* Sticky/Floating Cart Button */
.cart-float-button {
  position: fixed;
  top: 120px;
  right: 30px;
  z-index: 999;
  /* display: none; */
}
.cart-button {
  background-color: #e63946;
  color: #fff;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  position: relative;
}
.cart-button:hover {
  background-color: #d62839;
}
.cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #fff;
  color: #e63946;
  width: 20px;
  height: 20px;
  font-size: 0.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

/* Main Content */
.restaurant-content {
  flex: 1;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.cutoff-banner {
  background-color: #e63946;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.min-order {
  font-weight: bold;
}

/* Add or modify at bottom of RestaurantPage.css */

.delivery-modal .form-group.checkbox-inline {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.delivery-modal .form-group.checkbox-inline label {
  display: inline-flex;
  align-items: center;
  gap: inherit;
  cursor: pointer;
  margin-bottom: 0;
}

/* Category Bar */
.category-bar {
  display: flex;
  margin-bottom: 2rem;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.category-bar::-webkit-scrollbar {
  display: none;
}
.category-item {
  margin: 0 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid #e63946;
  background-color: #fff;
  color: #e63946;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}
.category-item.active,
.category-item:hover {
  background-color: #e63946;
  color: #fff;
}

/* Food Items Section */
.food-items-section {
  width: 100%;
}
.food-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1.5rem;
}
.food-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.food-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.food-card-content h3 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}
.food-card-content .price {
  color: #d62839;
  font-weight: 600;
  margin-top: 0.5rem;
}
.quantity-controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.quantity-controls button {
  background-color: #fff;
  border: 1px solid #e63946;
  color: #e63946;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.quantity-controls button:hover {
  background-color: #e63946;
  color: #fff;
}

/* CART MODAL (Backdrop + Slide-in) */
.cart-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  cursor: pointer;
}
.cart-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  z-index: 9999;
}
.cart-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.cart-modal-header h2 {
  font-size: 1.25rem;
  margin: 0;
}
.close-cart-button {
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  line-height: 1;
  padding: 0;
  margin: 0;
}
.empty-cart-msg {
  font-size: 1rem;
  margin-top: 1rem;
  color: #555;
}
.cart-items {
  list-style: none;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}
.cart-item .item-name {
  font-weight: 500;
  width: 40%;
}
.cart-item-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.icon-button {
  background-color: #fff;
  border: 1px solid #e63946;
  color: #e63946;
  padding: 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-button:hover {
  background-color: #e63946;
  color: #fff;
}
.delete-button {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 4px;
  padding: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-button:hover {
  background-color: #ccc;
}

/* Promo code area */
.promo-code-area {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.promo-code-area input {
  flex: 1;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.apply-promo-button {
  background-color: #2a9d8f;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}
.apply-promo-button:hover {
  background-color: #21867a;
}
.promo-message {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #d62839;
}

/* Cart breakdown totals */
.cart-breakdown {
  margin: 1rem 0;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}
.cart-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
.cart-row.total {
  font-weight: 600;
  font-size: 1rem;
}
.checkout-button {
  width: 100%;
  background-color: #e63946;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 0.5rem;
}
.checkout-button:hover {
  background-color: #d62839;
}

/* Add to the end of RestaurantPage.css */
/* Add to the end of RestaurantPage.css */
.tip-section {
  display: flex;
  padding: 1px 0;
  border-bottom: 1px solid #ccc;
  align-items: center;
  justify-content: space-around;
}

.tip-options {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.tip-button {
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  color: #333;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2sease;
  flex-grow: 1;
  margin: 0px 2px;
}

.tip-button.selected,
.tip-button:hover {
  background-color: #e63946;
  color: #fff;
  border-color: #e63946;
}

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 2;
}

.dollar-sign {
  position: absolute;
  left: 10px; /* Adjust this value based on padding of input */
  color: #333; /* Color of the dollar sign */
  font-size: 16px; /* Size of the dollar sign */
  z-index: 10;
}

.custom-tip-input {
  flex-grow: 1;
  padding: 0.5rem;
  padding-left: 2rem; /* Make space for the $ symbol inside the input */
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.2s ease;
  width: 100%; /* Ensure the input takes full width of the wrapper */
  background-color: #fff; /* Background color */
}
.custom-tip-input:focus {
  outline: none;
  border-color: #e63946;
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .header-image img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1rem;
  }
  .category-bar {
    justify-content: flex-start;
  }
  .cart-modal {
    width: 100%;
    max-width: 400px;
  }
}
