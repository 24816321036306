/* BrowseKitchensSkeleton.css */
.skeleton-page {
  width: 100%;
  overflow-x: hidden;
}

.skeleton-navbar {
  padding: 15px 20px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-logo {
  width: 100px;
  height: 30px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-nav-items {
  display: flex;
  gap: 20px;
}

.skeleton-nav-item {
  width: 60px;
  height: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-hero {
  padding: 40px 20px;
  background: linear-gradient(to bottom, rgb(0 0 0 / 90%), white);
}

.skeleton-hero-title {
  width: 300px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 0 auto 20px;
  animation: pulse 1.5s infinite;
}

.skeleton-hero-subtitle {
  width: 400px;
  height: 25px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 0 auto 40px;
  animation: pulse 1.5s infinite;
}

.skeleton-search-bar {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px;
}

.skeleton-geo-button {
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.skeleton-search-input {
  flex: 1;
  height: 40px;
  background: #e0e0e0;
  border-radius: 20px;
  animation: pulse 1.5s infinite;
}

.skeleton-search-button {
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.skeleton-action-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.skeleton-filter-button,
.skeleton-map-button {
  width: 100px;
  height: 35px;
  background: #e0e0e0;
  border-radius: 20px;
  animation: pulse 1.5s infinite;
}

.skeleton-kitchen-card {
  width: 280px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skeleton-card-image {
  width: 100%;
  height: 180px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite;
}

.skeleton-card-content {
  padding: 15px;
}

.skeleton-card-title {
  width: 70%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

.skeleton-card-subtitle {
  width: 50%;
  height: 15px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

.skeleton-card-details {
  width: 90%;
  height: 15px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-map {
  width: 100%;
  height: 500px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite;
}

.skeleton-footer {
  padding: 20px;
  background: #f5f5f5;
}

.skeleton-footer-content {
  width: 80%;
  height: 50px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 0 auto;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .skeleton-hero-title {
    width: 200px;
    height: 30px;
  }

  .skeleton-hero-subtitle {
    width: 250px;
    height: 20px;
  }

  .skeleton-search-bar {
    max-width: 100%;
  }
}
