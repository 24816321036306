.kitchen-images {
  animation: fadeIn 0.5s ease-out forwards;
  margin: 0 auto;
}

.image-upload-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  z-index: 1; /* Lower than DatePicker */
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-preview {
  position: relative;
  width: 120px;
  height: 120px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.remove-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #393939;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-icon-container {
  width: 120px;
  height: 120px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.upload-icon-container:hover {
  background-color: #f9f9f9;
  border-color: #393939;
}

.upload-icon {
  font-size: 40px;
  margin-bottom: 8px;
}

.image-entries {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.images-form-group {
  flex: 1;
}

.images-form-group label {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.images-form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .image-upload-section {
    flex-direction: column;
    align-items: center;
  }

  .image-preview-container {
    justify-content: center;
  }
}
