.profile-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9f9;
}

.profile-content {
  display: flex;
  flex-grow: 1;
  padding: 5px 0;
  background-color: #f9f9f9f9;
  width: 85%;
  margin: 0 auto;
}

.sidebar {
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
  margin-left: 10px;
  flex-direction: column;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .profile-content {
    width: 100%;
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .field-group {
    flex-direction: column;
    width: 100%;
  }

  .main-content {
    margin: 0 auto;
  }

  .image-preview-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .image-preview {
    width: 120px;
    height: 120px;
  }

  .image-entry {
    flex-direction: column;
  }

  .images-form-group {
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 480px) {
  .kitchen-images h2 {
    font-size: 22px;
  }

  .image-preview-container {
    gap: 8px;
  }

  .image-preview {
    width: 100px;
    height: 100px;
  }

  .add-image-btn {
    padding: 10px 16px;
    font-size: 14px;
  }
}
