.my-bookings-container {
  padding: 30px;
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
}

.my-bookings-container h1 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.bookings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}

.booking-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.booking-card:hover {
  transform: translateY(-4px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h3 {
  margin: 0;
  font-size: 1.25rem;
}

.card-header h3 a {
  color: #2c5282;
  text-decoration: none;
}

.card-header h3 a:hover {
  color: #2b6cb0;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
}

.status-confirmed {
  background: #2f855a;
}
.status-pending {
  background: #d69e2e;
}
.status-cancelled {
  background: #c53030;
}

.card-content {
  display: grid;
  gap: 20px;
}

.time-section,
.details-section,
.price-section {
  display: grid;
  gap: 8px;
}

.time-item,
.detail-item,
.price-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  color: #4a5568;
  font-weight: 500;
}

.value {
  color: #1a1a1a;
}

.price-item.total {
  border-top: 1px solid #e2e8f0;
  padding-top: 8px;
  font-weight: 600;
}

.invoice-btn {
  padding: 8px 16px;
  background: #2b6cb0;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.invoice-btn:hover {
  background: #2c5282;
}

.loading-state,
.no-data {
  text-align: center;
  padding: 40px;
  color: #718096;
  font-size: 1.125rem;
}

/* Modal Styles */
.booking-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.booking-modal {
  background: #fff;
  padding: 25px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  animation: fadeInCenter 0.3s ease-in-out;
  position: relative;
  margin: 0;
  transform: none;
}

.booking-modal-content {
  overflow-y: auto;
  padding-right: 10px;
  font-family: Arial, sans-serif;
}

.booking-modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.booking-modal-header h2 {
  margin: 0;
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 600;
}

.booking-modal-info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.booking-modal-company-info,
.booking-modal-customer-info {
  flex: 1;
}

.booking-modal-company-info {
  text-align: left;
}

.booking-modal-company-info h3 {
  margin: 0 0 8px;
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 500;
}

.booking-modal-company-info p,
.booking-modal-customer-info p {
  margin: 4px 0;
  color: #666;
  font-size: 12px;
}

.booking-modal-customer-info h4 {
  margin: 0 0 10px;
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 500;
}

.booking-modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.booking-modal-table th,
.booking-modal-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 12px;
}

.booking-modal-table th {
  background-color: #f5f5f5;
  color: #2d2d2d;
  font-weight: 600;
}

.booking-modal-cost-breakdown {
  margin-top: 20px;
  text-align: right;
}

.booking-modal-cost-breakdown h4 {
  margin: 0 0 10px;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 500;
}

.cost-breakdown-items {
  display: inline-block;
  text-align: right;
}

.cost-breakdown-item {
  display: flex;
  justify-content: space-between;
  width: 250px;
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
}

.cost-breakdown-item span:first-child {
  font-weight: 500;
}

.cost-breakdown-item.total {
  border-top: 1px solid #ddd;
  padding-top: 8px;
  font-weight: 600;
  color: #2d2d2d;
}

.booking-modal-instructions {
  margin-bottom: 20px;
}

.booking-modal-instructions h4 {
  margin: 0 0 10px;
  color: #2d2d2d;
  font-size: 12px;
  font-weight: 500;
}

.booking-modal-instructions p {
  font-size: 12px;
  color: #666;
  line-height: 1.6;
}

.booking-modal-link {
  color: #e53935;
  text-decoration: none;
  transition: color 0.3s ease;
}

.booking-modal-link:hover {
  text-decoration: underline;
}

.booking-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.booking-modal-download {
  padding: 8px 15px;
  background-color: #e53935;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.booking-modal-close {
  padding: 8px 15px;
  background-color: #393939;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.booking-modal-close:hover,
.booking-modal-download:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .bookings-grid {
    grid-template-columns: 1fr;
  }

  .booking-modal {
    padding: 15px;
    max-width: 100%;
  }
}

/* Ensure PDF rendering consistency */
@media print {
  .booking-modal-content {
    width: 800px !important;
    padding: 25px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    max-height: none !important;
  }
}
