.add-kitchen {
  animation: fadeIn 0.5s ease-out forwards;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 15px;
}

.add-kitchen h1 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.collapsible-panels {
  margin-bottom: 20px;
}

.panel {
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0eeee;
  padding: 10px 15px;
  cursor: pointer;
}

.panel-header h2 {
  font-size: 16px;
  margin: 0;
  color: #333;
}

.arrow-icon {
  font-size: 18px;
  color: #e53935;
}

.panel-content {
  padding: 15px;
  background-color: #fff;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group select {
  appearance: none;
  background-color: #fff;
  color: #333;
  width: 100%;
}

.char-limit {
  font-size: 12px;
  color: #767575;
  margin-top: 3px;
}

.terms-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.terms-container input[type="checkbox"] {
  accent-color: #393939;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.terms-container label {
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
}

.kitchen-terms-link {
  color: #393939;
  text-decoration: underline;
}

.tc-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tc-modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tc-modal-content h3 {
  margin-top: 0;
  font-size: 20px;
  color: #333;
}

.tc-modal-body {
  margin-top: 15px;
  color: #666;
  font-size: 14px;
}

.tc-modal-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #393939;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.submit-kitchen-btn {
  background-color: #333;
  border-radius: 4px;
  padding: 10px 0;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  max-width: 150px;
  text-align: center;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.submit-kitchen-btn:hover {
  background-color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .add-kitchen {
    width: 100%;
    padding: 5px;
    border-radius: 0;
    box-shadow: none;
  }
  .panel-header h2 {
    font-size: 18px;
  }
  .form-title {
    font-size: 22px;
  }
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 10px;
    font-size: 14px;
  }
}
